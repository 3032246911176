import {Button, ButtonGroup, Container, Row} from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeEmbed from "./YoutubeEmbed";
import { Link } from "react-router-dom";

const HeroContent = () => {
    return(
    
            <Container style={{background:'#ECE3E5'}}>
                <Row>
                    <h1 className='text-center' style={{font: 'Diphylleia', fontSize: '1em', marginTop: '2%'}}>Welcome to Las Vegas Housing Market Report – Your Premier Destination for In-Depth Insights into the Dynamic Real Estate Landscape of Las Vegas, Nevada! <br />At Las Vegas Housing Market Report, we pride ourselves on delivering comprehensive analyses, up-to-date trends and valuable data to empower both buyers and sellers in navigating the vibrant Las Vegas housing market. Stay informed with our regular updates on the latest market trends, insightful analysis and expert commentary, designed to help you make informed decisions in your real estate endeavors. <br /><br />Connect with us on social media to stay engaged and informed:<br />Follow our YouTube channel for video updates and in-depth discussions: <Link to= 'https://www.youtube.com/@anthonymadaffarirealtor4383'style={{ color: '#670038', fontWeight: 'bold' }}>YouTube</Link><br />Like us on Facebook for daily market insights and community engagement: <Link to='https://www.facebook.com/AnthonyMadaffariRealtor/'style={{ color: '#670038', fontWeight: 'bold' }}>Facebook</Link><br /> Follow us on Instagram for stunning visuals and behind-the-scenes content: <Link to= 'https://www.instagram.com/anthonymadaffarirealtor/'style={{ color: '#670038', fontWeight: 'bold' }}>Instagram</Link><br /> Explore our listings and reviews on Zillow to discover the finest properties Las Vegas has to offer: <Link to= "https://www.zillow.com/profile/AnthonyMadaffari/" style={{ color: '#670038', fontWeight: 'bold' }}>Zillow</Link><br /><br />Whether you're a prospective buyer, seller, or investor, Las Vegas Housing Market Report is your trusted partner in navigating the ever-evolving real estate landscape of Las Vegas.<br />Start your journey with us today and unlock the key to success in Las Vegas real estate!</h1>
               </Row>
                <Row>
                <h2  className='text-center' style={{font: 'Diphylleia', color: '#552448', fontSize: '2em', marginTop: '1.2%'}}>Las Vegas Housing Market Report</h2>
                </Row>
                <Row >
                    <YoutubeEmbed embedId="L3xs2kHiyG4" />
                </Row>
                <div className="row-content  text-center">
                    <ButtonGroup>
                        <a href="https://lasvegashomebuyersmarket.com/" target="_blank" rel="noopener noreferrer">
                            <Button className="shadow-5-strong"style={{fontFamily: 'Diphylleia', fontSize: 'medium', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                                Buyers
                            </Button>
                        </a> 

                        <a href="https://anthonymadaffari.bhhsnv.com/" target="_blank" rel="noopener noreferrer">

                            <Button className="shadow-5-strong"style={{marginLeft: '0.5rem',fontFamily: 'Diphylleia', fontSize: 'medium', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                                Sellers
                            </Button>
                        </a>
                        <a href="https://bhhsnv.findbuyers.com/tonym@bhhsnv.com" target="_blank" rel="noopener noreferrer">
                            <Button style={{marginLeft: '0.5rem', fontFamily: 'Diphylleia', fontSize: 'medium', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                                Home Value
                            </Button>
                        </a>
                    </ButtonGroup>
                </div>
            </Container> 
                                   
            
         
    );
};


export default HeroContent;