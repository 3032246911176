import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">    
        <iframe 
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${embedId}`}
        title="YouTube video player" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowFullScreen
        />
    </div>
);

YoutubeEmbed.propTypes = {
embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;