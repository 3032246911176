import NameWithImage from './NameWithImage';
import { Container, Row, Col } from 'reactstrap';
import logo from '../app/assets/images/Berkshire.jpg';

const Header = () => {
  return (
    <Container className='site-header container-sm'>
      <Row>
        <Col lg="4">
          <NameWithImage/>
        </Col>
        <Col  lg="6">
          <h1 className='text-left' style={{font: 'Diphylleia', color: '#a7a9ac',fontSize: '2.4em', marginTop: '5%'}}>BHHS NV Properties</h1>
        </Col>
        <Col lg="2">
          <a href="https://anthonymadaffari.bhhsnv.com/" target="_blank" rel="noopener noreferrer">
            <img  src={logo} style= {{marginTop: '5%'}} alt="las vegas housing market report" aria-label= "las vegas housing market report" width={90}/>
          </a>
        </Col>
      </Row>       
    </Container>
  );
};

export default Header;